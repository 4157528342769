<template>
    <div class="show-profile-container">
        <section>
            <h1>個人資料</h1>
            <div class="table">
                <div>姓名</div>
                <div>{{ profileData.name }}</div>
                <div>性別</div>
                <div>{{ genderInText }}</div>
                <div>年齡</div>
                <div>{{ profileData.age }}</div>
                <div>出生日期</div>
                <div>{{ profileData.birthday }}</div>
                <div>電話號碼</div>
                <div>{{ profileData.phone }}</div>
                <div>身份證號碼</div>
                <div>{{ profileData.idCardNumber }}</div>
            </div>
        </section>
        <section>
            <h1>地址資料</h1>
            <div class="table">
                <div>行政區</div>
                <div>{{ profileData.addressProvince }}</div>
                <div>區域</div>
                <div>{{ profileData.addressArea }}</div>
                <div>地區</div>
                <div>{{ profileData.addressRegion }}</div>
                <div>住宅地址行</div>
                <div>{{ profileData.address1 }}</div>
            </div>
        </section>
        <section>
            <h1>緊急聯絡人</h1>
            <div class="table">
                <div>姓名</div>
                <div>{{ profileData.emergencyContact }}</div>
                <div>電話</div>
                <div>{{ profileData.emergencyContactPhone }}</div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: false,
            default: "person",
            validator: function (value) {
                return ["person", "patient"].includes(value);
            },
        },
    },
    data: function () {
        return {
            profileData: {},
        };
    },
    computed: {
        genderInText() {
            return this.profileData.gender === 1 ? "男" : "女";
        },
    },
    mounted: function () {
        if (this.type === "person") {
            this.profileData = this.$store.state.profile.myProfileData;
        } else if (this.type === "patient") {
            this.profileData = this.$store.state.profile.patientProfileData;
        }
    },
};
</script>

<style lang="scss" scoped>
@mixin grid($rows) {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: repeat($rows, 1fr);
}
.show-profile-container {
    flex-grow: 1;
    section {
        margin-bottom: 16px;
        @include flexc-start-stretch;
        h1 {
            color: white;
            padding: 16px;
            background-color: $green-300;
            font-size: 21px;
            font-weight: $semi-bold;
            margin: 0;
        }
        .table {
            background-color: white;
            div {
                border-bottom: 1px solid $black;
                font-size: 16px;
                padding: 16px;
            }
            div:last-child,
            div:nth-last-child(2) {
                border-bottom: none;
            }
        }
    }
    section:nth-of-type(1) .table {
        @include grid(6);
    }
    section:nth-of-type(2) .table {
        @include grid(4);
    }
    section:nth-of-type(3) .table {
        @include grid(2);
    }
}
</style>