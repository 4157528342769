<template>
    <div class="edit-profile-container">
        <section>
            <el-upload
                v-if="type === 'person'"
                accept="image/*"
                :onSuccess="onUploadSuccess"
            >
                <span v-if="!form.avatar"> 上傳圖片 </span>
                <img v-else :src="form.avatar" alt="" />
            </el-upload>
            <el-input v-model="form.name" placeholder="輸入姓名"></el-input>
            <el-select v-model="form.gender" placeholder="選擇性別">
                <el-option label="男" :value="1"></el-option>
                <el-option label="女" :value="2"></el-option>
            </el-select>
            <el-date-picker
                v-model="form.birthday"
                placeholder="輸入出生日期"
            ></el-date-picker>
            <el-input v-model="form.phone" placeholder="輸入電話"></el-input>
            <el-select v-model="form.idCardRegion">
                <el-option :value="1" label="香港身份證"></el-option>
                <el-option
                    :value="2"
                    label="中華人民共和國居民身份證"
                ></el-option>
            </el-select>
            <el-input
                v-model="form.idCardNumber"
                placeholder="輸入身份證號碼"
            ></el-input>
        </section>
        <section>
            <h1>地址</h1>
            <address-input
                theme="blue"
                v-model="form.addressData"
            ></address-input>
        </section>
        <section>
            <h1>緊急聯絡人</h1>
            <el-input
                v-model="form.emergencyContact"
                placeholder="緊急聯絡人姓名"
            ></el-input>
            <el-input
                v-model="form.emergencyContactPhone"
                placeholder="緊急聯絡人電話"
            ></el-input>
        </section>
        <button @click="save" class="rounded-primary">保存</button>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import addressInput from "../../../components/addressInput.vue";
export default {
    components: { addressInput },
    props: {
        type: {
            type: String,
            required: false,
            default: "person",
            validator: function (value) {
                return ["person", "patient"].includes(value);
            },
        },
    },
    data: function () {
        return {
            profileData: {},
            form: {
                id: "",
                avatar: "",
                name: "",
                gender: "",
                birthday: "",
                phone: "",
                idCardNumber: "",
                idCardRegion: 1,
                addressData: {
                    address1: "",
                    addressArea: "",
                    addressRegion: "",
                    addressProvince: "",
                },
                emergencyContact: "",
                emergencyContactPhone: "",
            },
        };
    },
    methods: {
        ...mapActions({
            updateMyProfile: "profile/updateMyProfile",
            updatePatientProfile: "profile/updatePatientProfile",
        }),
        save() {
            let form = JSON.parse(JSON.stringify(this.form));
            Object.assign(form, form.addressData);
            delete form.addressData;
            for (let key of Object.keys(form)) {
                let value = form[key];
                if (key === "id") continue;
                if (value != "" && value != null && value != undefined)
                    continue;
                else if (this.type != "patient") {
                    this.$message({
                        type: "error",
                        message: "請完整填寫資料",
                    });
                    return false;
                } else continue;
            }
            if (this.type === "person") {
                this.updateMyProfile(form)
                    .then(() => {
                        this.$emit("save");
                    })
                    .catch((err) => {
                        this.$message({
                            type: "error",
                            message: err.message,
                        });
                    });
            } else if (this.type === "patient") {
                this.updatePatientProfile(form)
                    .then(() => {
                        this.$emit("save");
                    })
                    .catch((err) => {
                        this.$message({
                            type: "error",
                            message: err.message,
                        });
                    });
            }
        },
        onUploadSuccess(res) {
            this.form.avatar = res.data.fileUrl;
        },
    },
    mounted: function () {
        if (this.type === "person") {
            this.profileData = this.$store.state.profile.myProfileData;
        } else if (this.type === "patient") {
            this.profileData = this.$store.state.profile.patientProfileData;
        }
        for (let key of Object.keys(this.form.addressData)) {
            this.form.addressData[key] = this.profileData[key];
        }
        for (let key of Object.keys(this.form)) {
            if (key === "addressData") continue;
            this.form[key] = this.profileData[key];
        }
    },
};
</script>

<style lang="scss" scoped>
.edit-profile-container {
    flex-grow: 1;
    padding: 0 16px 16px;
    section {
        border-bottom: 1px solid $black;
        .el-input::v-deep,
        .el-select::v-deep {
            input {
                background-color: transparent;
            }
        }

        h1 {
            color: $blue-250;
            font-weight: $semi-bold;
            font-size: 24px;
            margin: 16px 0;
        }
    }
    section:last-of-type {
        border-bottom: none;
    }
    .rounded-primary {
        margin: 16px auto;
        width: 60%;
    }
    section::v-deep {
        .el-upload {
            width: 40vw;
            height: 40vw;
            border-radius: 50%;
            border: 1px dashed $black;
            @include flexr-center-center;
            margin: 0 auto 16px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
</style>